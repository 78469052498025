/* 
* Use this file to import vendor CSS files from your node_modules directory.
*/

/* AOS */
$aos-distance: 25px; /* custom AOS animation distance */
@import '~aos/src/sass/aos.scss';


/* Swiper.css */
// include PAth in webpack.cpnfig
//@import "~swiper/swiper-bundle.css";


/* Plyr */
@import '~plyr/dist/plyr.css';